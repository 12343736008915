var app = new Vue({

  el: '#app',
  data: {
		nombre: '',
		faltaNombre: false,
		celular: '',
		faltaCelular: false,
		email: '',
		faltaEmail: false,
		mensaje: '',
		faltaMensaje: false,
		form: true,
		enviandomensaje: false,
		titulo: 'LABORATORIO CLÍNICO ARTURO FERNÁNDEZ'
	  },
	  mounted() {
	  	if (this.isMobile()) {
			  this.titulo = ''
			}
	  },
	  methods: {
	  isMobile() {
		  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
		  return regex.test(navigator.userAgent);
		},
		resetForm () {
			this.nombre = ''
			this.faltaNombre = false
			this.celular = ''
			this.faltaCelular = false
			this.email = ''
			this.faltaEmail = false
			this.mensaje = ''
			this.faltaMensaje = false
		},
		cambioNombre () {
			if (this.nombre !== '') {
				this.faltaNombre = false
			} else {
				this.faltaNombre = true
			}
		},
		cambioCelular () {
			if (this.celular !== '') {
				this.faltaCelular = false
			} else {
				this.faltaCelular = true
			}
		},
		cambioEmail () {
			if (this.email !== '' & this.validEmail(this.email) === true) {
				this.faltaEmail = false
			} else {
				this.faltaEmail = true
			}
		},
		cambioMensaje () {
			if (this.mensaje !== '') {
				this.faltaMensaje = false
			} else {
				this.faltaMensaje = true
			}
		},
		validarForm () {
			if (this.nombre !== '' & this.faltaNombre === false & this.celular !== '' &
				this.faltaCelular === false & this.email !== '' & this.faltaEmail === false &
				this.mensaje !== '' & this.faltaMensaje === false) {
				return true
			} else {
				return false
			}
		},
		validEmail: function (email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		async enviarMensaje () {
			if (this.validarForm() === true) {
				const data = {
					nombre: this.nombre,
					celular: this.celular,
					email: this.email,
					mensaje: this.mensaje
				}
			  try {
				this.enviandomensaje = true
				this.form = false
				const response = await axios.post('./ajax/mailcontacto.php', data)
				if (response.status === 200) {
				  Swal.fire(
					'Mensaje Enviado',
					'Pronto recibirá una respuesta en el correo registrado :)',
					'success'
				  )
				} else {
				  Swal.fire(
					'Error',
					'Ha ocurrido un error...',
					'error'
				  )
				}
			  } catch (error) {
				Swal.fire(
				  'Error',
				  'El servidor de mensaje está caído. Puede enviarnos también un mensaje por nuestro Chat de Facebook :)',
				  'error'
				)
			  }
					} else {
			  Swal.fire(
				'Mmmm...',
				'Faltan datos por completar...',
				'error'
			  )
					}
			this.enviandomensaje = false
			this.resetForm()
			this.form = true
		}
	},
	watch: {
		nombre () {
			this.cambioNombre()
		},
		email () {
			this.cambioEmail()
		},
		celular () {
			this.cambioCelular()
		},
		mensaje () {
			this.cambioMensaje()
		}
	}
})